// bottles
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const bottlesAPI = axios.create({
    baseURL,
});

export const getIcons= async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/icons`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showIcons = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/icons/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addIcons = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.post(`${baseURL}api/icons`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteIcons = async (Id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.delete(`${baseURL}api/icons/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateIcons = async (LanguageID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.put(`${baseURL}api/icons/${LanguageID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
