import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Popconfirm, Select,
    Table,
    Upload
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, UploadOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {addDriver, deleteDriver, getDrivers, showDriver, updateDriver} from "../../../api/service_drivers";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {deleteUser, getAllUsers} from "../../../api/users_api";
import {FaUsersGear} from "react-icons/fa6";
import {PiTelegramLogoThin} from "react-icons/pi";

export default function Users() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [drivers, setDrivers] = useState([])
    const [driversOld, setDriversOld] = useState([])
    const [selectedDriver, setSelectedDriver] = useState([]);


    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);

    const [image, setImage] = useState(null);

    const [imageDoc,setImageDoc] = useState(null)

    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit()
        setIsModalOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };

    const handleDocumentImage = (info) => {
        setImageDoc(info.file)
    };


    useEffect(() => {
        fetchDrivers()
    }, [])

    const fetchDrivers = async () => {
        setLoading(true);
        try {
            const data = await getAllUsers();
            setDrivers(data);
            setDriversOld(data)
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
        setLoading(false);
    };





    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                values.photo = values.photo.file
                values.document = values.document.file

                await updateDriver(selectedDriver.id,values);
                setDrivers(drivers)
                message.success("водитель успешно добавьлен в сервер")
                await fetchDrivers()
            } else {
                values.photo = values.photo.file
                values.document = values.document.file
                await addDriver(values);
                setDrivers(drivers)
                message.success("админ успешно добавьлен в сервер")
                await fetchDrivers()
            }
        } catch (error) {
            console.log('Error adding drivers ', error)
        }
    }
    const deleteButtonProps = {
        style: {
            backgroundColor: 'red', // Set the default background color
        },
        // Add other props as needed
    };
    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'To`liq F.I.O',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Tel ',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render:(phoneNumber)=>(
                <p className={'bg-green-600 rounded text-white w-fit p-[3px] cursor-pointer'}> + {phoneNumber}</p>
            )
        },
        {
            title: 'Telegram ID ',
            dataIndex: 'telegram_id',
            key: 'telegram_id',
            render:(telegram_id)=>(
                <div className={'w-full flex flex-row bg-blue-400 rounded text-white w-fit p-[3px] cursor-pointer items-center justify-center'}>
                    <PiTelegramLogoThin className={'m-2'}/>
                    <p  >  {telegram_id}</p>
                </div>
            )
        },
        {
            title: 'Role ',
            dataIndex: 'roles',
            key: 'roles',
            render:(roles)=>(
                <div className={`w-full flex flex-row   text-white w-fit p-[1px] cursor-pointer items-center justify-center`}>
                    {roles?.map(value=>(
                        <div className={`w-fit p-[3px] rounded ${value.name === 'courier'? 'bg-blue-400 rounded' : value.name === 'admin'?'bg-green-600':value.name ==='client'? 'bg-blue-800':''} `}>{value.name}</div>
                    ))}
                </div>
            )
        },
        {
            title: 'Manzil',
            dataIndex: 'address',
            key: 'address',
            render:(address)=>(
                <div className={'w-full flex flex-row bg-orange-400 rounded text-white w-fit p-[3px] cursor-pointer items-center justify-center'}>
                    <HomeOutlined className={'m-2'}/>
                <p  >  {address}</p>
                </div>
            )
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>


                    <Popconfirm
                        title="Clientni o`chirish"
                        description="Are you sure to delete this admin?"
                        onConfirm={async () => {

                            await deleteUser(record.id)
                            fetchDrivers()
                        }
                        }
                        okButtonProps={deleteButtonProps}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'To`liq F.I.O',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Tel ',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(drivers, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[drivers])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setDrivers(driversOld)
        } else {
            setDrivers(filterDrivers(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterDrivers(value) {
        return drivers.filter(driver => {

            return driver.fullname.toLowerCase().includes(value) || driver.phoneNumber.toLowerCase().includes(value) || driver.address.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_driver}>

                <div className={styles.title}>
                   Все пользователи
                    <FaUsersGear className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button

                            onClick={showModal}
                            disabled
                            className={'bg-green-600'}
                        >
                            Пользователь +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the driver form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addDrivers"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Full name"
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your fullname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone number"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input placeholder="Phone number" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please, enter password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                        label="Изображение"
                        name="photo"
                    >
                        <Upload
                            accept="image/*"
                            maxCount={1}
                            beforeUpload={() => false}
                            listType="picture"
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                            onChange={handleImage}
                        >
                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Изображение документа"
                        name="document"
                    >
                        <Upload
                            accept="image/*"
                            maxCount={1}
                            beforeUpload={() => false}
                            listType="picture"
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                            onChange={handleDocumentImage}
                        >
                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                        </Upload>
                    </Form.Item>



                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={drivers} columns={columns}/>
            </div>

            <Drawer  title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'w-9/12 h-fit'}>
                        <img src={`https://testarena.site/storage/${selectedDriver?.photo}`}
                             className={'w-full h-full object-cover'}/>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Full name : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriver?.fullname}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Phone number : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriver?.phone}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Driver warehouse : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriver?.DriverWarehouses_id}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Driver card : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriver?.DriverCard_id}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
