import React, {useEffect, useState} from "react";
import "./style.css";
import {
    Button,
    Drawer,
    Dropdown,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Table,
    Upload
} from "antd";
import {
    DeleteOutlined, DollarOutlined,
    EditOutlined,
    EyeOutlined,
    HomeOutlined,
    MinusOutlined, MoneyCollectOutlined,
    PlusOutlined,
    UploadOutlined
} from "@ant-design/icons";
import { deleteAdmin, showAdmin, updateAdmin} from "../../../api/service_admins";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {useSelector} from "react-redux";
import {addUserWithRole, deleteUser, getUser, showUser, updateUser} from "../../../api/users_api";
import {PiTelegramLogoThin} from "react-icons/pi";
import {
    decreaseCourierCardCount,
    getCourierCard,
    getCourierCardById,
    increaseCourierCardCount
} from "../../../api/ACC/CourierCardApi";
import {BiCard} from "react-icons/bi";
import {decreaseWarehouseCount, increaseWarehouseCount} from "../../../api/ACC/WarehousesApi";

export default function DriverCards() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [admins, setAdmins] = useState([])
    const [adminsOld, setAdminsOld] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState([]);

    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [form] = Form.useForm();


    const theme = useSelector((state) => state.theme.theme);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };
    const actionColumn = {
        title: 'sonini o`zgartirish',
        key: 'action',
        render: (text, record) => {
            let count = 1; // Значение для увеличения по умолчанию

            // Функция для увеличения количества
            const handleIncrease = async () => {
                try {
                    await increaseCourierCardCount(record.id, count);
                    await fetchAdmins()
                    message.success('maxsulot muvoffaqiyatli qo`shildi')
                    // Обновляем данные, если нужно
                } catch (error) {
                    message.error('Internetni tekshiring yoki xatolik yuz berdi')
                }
            };

            // Функция для уменьшения количества
            const handleDecrease = async () => {
                try {
                    if (record.product_count < count) {
                        message.warning('kamayish maxsulotlar sonidan ko`p bo`la olmaydi')
                    } else {
                        await decreaseCourierCardCount(record.id, count);
                        await fetchAdmins()
                        message.success('maxsulot muvoffaqiyatli kamaydi')
                    }
                    // Обновляем данные, если нужно
                } catch (error) {
                    message.error('Internetni tekshiring yoki xatolik yuz berdi')
                }
            };

            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <InputNumber min={1} defaultValue={1} onChange={(value) => (count = value)}
                                 style={{marginRight: '8px'}}/>
                    <Button className={'bg-green-600 text-white'} onClick={handleIncrease} icon={<PlusOutlined/>}
                            style={{marginRight: '8px'}}/>
                    <Button className={'bg-red-600 text-white'} onClick={handleDecrease} icon={<MinusOutlined/>}/>
                </div>
            );
        }
    };

    useEffect(() => {
        fetchAdmins()
    }, [])

    const fetchAdmins = async () => {
        setLoading(true);
        try {
            const data = await getCourierCard();
            setAdmins(data);
        } catch (error) {
            console.error('Curyerlarda xatolik', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){

                await updateUser(selectedAdmin.id,values);
                setAdmins(admins)
                message.success("Xaydovchi cartasi o`zgardi")
                await fetchAdmins()
            } else {

                setAdmins(admins)
                message.success("Client movoffaqqiyatli qo`shildi")
                await fetchAdmins()
            }
        } catch (error) {
            console.log('Error adding admins ', error)
        }
    }

    const deleteButtonProps = {
        style: {
            backgroundColor: 'red', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'To`liq F.I.O',
            dataIndex: 'user',
            key: 'user',
            render: user => user?.fullname

        },
        {
            title: 'Xamyon',
            dataIndex: 'cash',
            key: 'cash',
        },
        {
            title: 'Maxsulotlar soni',
            dataIndex: 'product_count',
            key: 'product_count',
        },
        actionColumn,
        {
            title: 'olishi mumkin bo`lgan pul',
            dataIndex: 'product_count',
            key: 'product_count',
            render:record => parseInt(record) * 800 + ' so`m'
        },
        {
            title: 'Xolati',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Mashina raqami',
            dataIndex: 'car_number',
            key: 'car_number',
        },

        {
            title: 'Amallar',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await getCourierCardById(record.id);
                        setSelectedAdmin(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>

                    {/*<div onClick={()=>{*/}
                    {/*    setEdit(true)*/}
                    {/*    setSelectedAdmin(record.courier)*/}
                    {/*}} className={'action_buttons bg-amber-500 text-white'}>*/}
                    {/*    <EditOutlined/>*/}
                    {/*</div>*/}

                    {/*<Popconfirm*/}
                    {/*    title="Clientni o`chirish"*/}
                    {/*    description="Are you sure to delete this admin?"*/}
                    {/*    onConfirm={async () => {*/}

                    {/*        await deleteUser(record.courier.id)*/}
                    {/*         await fetchAdmins()*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*    okButtonProps={deleteButtonProps}*/}
                    {/*    okText="Yes"*/}
                    {/*    cancelText="No"*/}
                    {/*>*/}
                    {/*    <div className={'action_buttons bg-red-600 text-white'}>*/}
                    {/*        <DeleteOutlined/>*/}
                    {/*    </div>*/}
                    {/*</Popconfirm>*/}


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: 'Full name',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(admins, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[admins])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setAdmins(adminsOld)
        } else {
            setAdmins(filterAdmins(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterAdmins(value) {
        return admins.filter(admin => {

            return admin.fullname.toLowerCase().includes(value) || admin.phone.toLowerCase().includes(value);

        });
    }

    return <div className={'w-full'}>
        <div className={` w-[100%] p-0`}>
            <div className={`header_admin`}>

                <div className={`title`}>
                    Карта курьеров
                    <BiCard className={'text-[28px]'}/>
                </div>
                <div className={`bg-green-600 text-xl font-semibold font-sans text-black/60 m-2 p-1  rounded-lg text-white flex flex-row items-center gap-x-2`}>
                    Bitta boklashka uchun 800 so`m xisoblanmoqda
                    <DollarOutlined className={'text-[28px]'}/>
                </div>

                <div className={`tools`}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            disabled={true}
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Добавить карту курьеров +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'}  mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Formani to`ldiring" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Kuryer"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="To`liq ismi"
                        name="fullname"
                        rules={[
                            {
                                required:!edit,
                                message: 'Please input your fullname!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        label="Telefon raqami"
                        name="phoneNumber"
                        rules={[{required: !edit, message: 'ILtimos telefon raqamni kiriting!'}]}
                    >
                        <Input placeholder="Telefon raqami"/>
                    </Form.Item>

                    <Form.Item
                        label="Chat id"
                        name="chat_id"
                        rules={[{required: !edit, message: 'ILtimos Chat id kiriting!'}]}
                    >
                        <Input placeholder="Telefon raqami"/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: !edit, message: 'ILtimos parolni kiriting!'}]}
                    >
                        <Input.Password placeholder="Password"/>
                    </Form.Item>

                    <Form.Item
                        label="Manzil"
                        name="address"
                        rules={[{required: !edit, message: 'ILtimos manzilni kiriting!'}]}
                    >
                        <Input placeholder="Mainzil"/>
                    </Form.Item>


                    <Form.Item
                        label="Telegram_id"
                        name="telegram_id"
                        rules={[{required: !edit, message: 'ILtimos telegramni id ni kiriting!'}]}
                    >
                        <Input placeholder="Telegram id"/>
                    </Form.Item>

                </Form>
            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`} `}>
                <Table className={'shadow-2xl w-full rounded-xl'} dataSource={admins} columns={columns}/>
            </div>

            <Drawer title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'w-9/12 h-fit'}>
                        <img src={`https://testarena.site/storage/${selectedAdmin?.photo}`}
                             className={'w-full h-full object-cover'}/>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Full name : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.fullname}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Phone number : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.phone}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
