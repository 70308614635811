import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
    Button, Carousel, Drawer, Form, Input, InputNumber, message, Modal, Popconfirm, Popover, Select, Switch, Table, Typography
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { LiaProductHunt } from "react-icons/lia";
import { Excel } from "antd-table-saveas-excel";
import { SiMicrosoftexcel, SiShortcut } from "react-icons/si";
import { useSelector } from "react-redux";
import { getImages } from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import { addProducts, deleteProducts, getProducts, showProducts, updateProducts } from "../../../api/products_api";
import { BiMoney } from "react-icons/bi";
import { ImImage } from "react-icons/im";
import { getLanguage } from "../../../api/language";
import { getCategoryByLang } from "../../../api/Category_api";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";

export default function Products() {

    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [bottles, setBottles] = useState([]);
    const [bottlesOld, setBottlesOld] = useState([]);
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isHalf, setIsHalf] = useState(false);
    const [hasSizes, setHasSizes] = useState(false); // Для управления видимостью полей размеров
    const [isCashBack, setIsCashback] = useState(false); // Для управления видимостью поля кэшбека
    const [hasCheesBoard, setHasCheesBoard] = useState(false); // Для управления видимостью поля сыра
    const [selectedlanguages, setSelectedLanguages] = useState([]);
    const [types, setTypes] = useState([]);
    const [brandId, setBrandId] = useState();
    const [brief, setBreif] = useState('');
    const [description, setDescription] = useState('');
    const [topIcons, setTopIcons] = useState([]);
    const [covers, setcovers] = useState([]);
    const [isIcon, setIsIcon] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [filteredImages, setFilteredImages] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectDescription, setSelectDescription] = useState();
    const [topiconsList, setTopiconsList] = useState([{ name: '', price: 0 }]);
    const [form   ] = Form.useForm();
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [modalVisibleTable, setModalVisibleTable] = useState(false);
    const [selectedImageTable, setSelectedImageTable] = useState('');


    const handleImageClick = (imageUrl) => {
        setSelectedImageTable(imageUrl);
        setModalVisibleTable(true);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit();
        setEdit(false);
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false);
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        fetchLanguage();
        fetchBottles();
        fetchType('uz');
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data);
            setImages(data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }
    const fetchBottles = async () => {
        setLoading(true);
        try {
            let lang = 'uz';
            if (languages.length !== 0) {
                lang = languages[0].code;
            }
            const data = await getProducts(lang);
            console.log(data)
            setBottles(data);
            setBottlesOld(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        try {
            if (edit) {
                if (brief.length !== 0){
                    values.brief_description = brief;
                }
                if (description.length !== 0){
                    values.description = description;
                }
                if (isCashBack){
                    values.cashback_price = isCashBack ? values.cashback_price : 0;
                }
                if(hasCheesBoard){
                    values.chees_board_price = hasCheesBoard ? values.chees_board_price : null;
                }
                if (covers.length !== 0){
                    values.cover = covers;
                }
                if (isHalf){
                    values.isHalf = 1
                } else {
                    values.isHalf = 0
                }
                if (isDisabled){
                    values.isDisabled = 1;
                } else {
                    values.isDisabled = 0;
                }

                if (topIcons.length !== 0){
                    values.topicons = topiconsList;
                }
                await updateProducts(selectedBottles.id, values);
                setBottles(bottles);
                message.success("Продукт успешно обновлен");
                await fetchBottles();
            } else {
                values.brief_description = brief;
                values.description = description;
                values.cover = covers;
                values.chees_board_price = hasCheesBoard ? values.chees_board_price : null;
                values.topicons = topiconsList;
                await addProducts(values);
                setBottles(bottles);
                message.success("Продукт успешно добавлен");
                await fetchBottles();
            }
        } catch (error) {
            console.log('Ошибка при добавлении продукта ', error);
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <BiMoney className={'mr-[5px] text-green-700'} />
                    <span className={'text-green-700'}>Цена</span>
                </div>
            ),
            dataIndex: ['price'],
            key: 'price',
            render: (text, record) => (
                <div className={'bg-green-600 text-white p-1 rounded-md text-[12px] cursor-pointer text-center'}>
                    <Popover placement="rightTop" title={'Цена'} content={() => (
                        <div>
                            цена : {record['price']}
                            <br />
                        </div>
                    )}> {record['price'].length > 8 ? record['price'].substring(0, 8) + " ... " : record['price']} руб.
                    </Popover></div>
            )
        },
        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <InfoCircleOutlined className={'mr-[5px] text-teal-700'} />
                    <span className={'text-teal-700'}>Полное описание</span>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            render: (text) => (
                <span
                    className={'bg-blue-800 text-white rounded cursor-pointer p-1 text-center w-full '}

                    onClick={() => {
                        showModal2();
                        setSelectDescription(text);
                    }}
                >
                    {text?.length > 40 ? `${text?.substring(0, 40)}...` : text}
                </span>
            ),
        },
        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <SiShortcut className={'mr-[5px] text-blue-700'} />
                    <span className={'text-blue-700'}>Краткое описание</span>
                </div>
            ),
            dataIndex: 'brief_description',
            key: 'brief_description',
        },
        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <ImImage className={'mr-[5px] text-blue-900'} />
                    <span className={'text-blue-900'}>Обложки</span>
                </div>
            ),
            dataIndex: 'cover',
            key: 'cover',
            render: (cover) => (
                <div className={'w-full'}>
                    {cover.map((value, index) => (
                        <img
                            key={index}
                            onClick={() => handleImageClick(process.env.REACT_APP_URL + value)}
                            src={`https://api.italianopizza.uz/${value}`}
                            className={'inline-block mr-2 mb-2 w-[25px] h-[25px] cursor-pointer'}
                            alt={`covers-${index}`}
                        />
                    ))}
                </div>
            )

        },
        {
            title: 'Действия',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showProducts(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined />
                    </div>

                    <Popconfirm
                        title="Удалить продукт"
                        description="Вы действительно хотите удалить этот продукт?"
                        onConfirm={async () => {
                            await deleteProducts(record.id);
                            await fetchBottles();
                        }}
                        okText="Да"
                        cancelText="Нет"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined />
                        </div>
                    </Popconfirm>
                </div>
            }
        }
    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("Продукты")
            .addColumns(columns)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                value.img_category.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    const fetchLanguage = async () => {
        try {
            const data = await getLanguage();
            setLanguages(data);
        } catch (e) {
            console.error('Error fetching languages:', e);
        }
    }

    const fetchType = async (lang) => {
        try {
            const data = await getCategoryByLang(lang);
            setTypes(data);
        } catch (e) {
            console.error('Error fetching types:', e);
        }
    }

    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {
        if (values.target.value.length === 0) {
            setBottles(bottlesOld);
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()));
        }
    }

    const iconsHandler = (value) => {
        let newIcons;
        if (!isIcon) {
            newIcons = [...topIcons];
        } else {
            newIcons = [...covers];
        }
        const index = newIcons.indexOf(value);

        if (index === -1) {
            newIcons.push(value);
        } else {
            newIcons.splice(index, 1);
        }

        !isIcon ? setTopIcons(newIcons) : setcovers(newIcons);
    };

    function filterBottles(value) {
        return bottles.filter(bottle => {
            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.Price.toLowerCase().includes(value);
        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900',
        }
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff',
        }
    };

    const handleLanguage = async (e) => {
        setLoading(true);
        try {
            const data = await getProducts(e);
            setBottles(data);
            setBottlesOld(data);
        } catch (error) {
            console.error('Error fetching bottles:', error);
        }
        setLoading(false);
    }

    const handleTopiconChange = (index, field, value) => {
        const newTopicons = [...topiconsList];
        newTopicons[index][field] = value;
        setTopiconsList(newTopicons);
    };

    const addTopicon = () => {
        setTopiconsList([...topiconsList, { name: '', price: 0 }]);
    };

    const removeTopicon = (index) => {
        const newTopicons = [...topiconsList];
        newTopicons.splice(index, 1);
        setTopiconsList(newTopicons);
    };

    return (
        <div className={'w-full'}>
            <div className={styles.container}>
                <div className={styles.header_bottles}>

                    <div className={styles.title}>
                        Продукты
                        <LiaProductHunt className={'text-[28px]'} />
                    </div>

                    <div className={styles.tools}>
                        <div className={'flex flex-row items-center gap-x-2'}>
                            <Button
                                onClick={showModal}
                                type="primary"
                                className={'bg-green-600'}
                            >
                                Добавить продукт +
                            </Button>

                            <Button type={"primary"} onClick={handleClick} className={'bg-blue-400 flex flex-row text-xl items-center'}>
                                <SiMicrosoftexcel />
                            </Button>
                        </div>

                        <div className={'flex flex-row items-center'}>
                            <Select placeholder={'Выберите язык'} className={'w-[200px] mr-2'} onChange={handleLanguage}>
                                {languages?.map((type) => (
                                    <Option value={type.code} key={type.code}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                            <div className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>
                                Поиск
                            </div>
                            <Input
                                placeholder="Поиск"
                                allowClear
                                onChange={onSearch}
                                style={{ width: 200 }}
                            />
                        </div>

                    </div>

                </div>

                <Modal title="Продукт" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel} style={{ height: '550px', overflow: 'scroll' }}>
                    <Form
                        form={form}
                        name="Добавить продукт"
                        initialValues={{ remember: true, cashbackPercent: 0 }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="доступен ли продукт"
                            name="isDisabled"
                            rules={[
                                { required: !edit, message: 'Пожалуйста, выберите доступность продукта!' }
                            ]}
                        >
                            <Switch
                                className={'bg-blue-300'}
                                checked={isDisabled}
                                onChange={setIsDisabled}
                                checkedChildren="Нет"
                                unCheckedChildren="Да"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Выберите язык"
                            name="language_id"
                            rules={[
                                { required: !edit, message: 'Пожалуйста, выберите язык!' }
                            ]}
                        >
                            <Select
                                onChange={(value) => {
                                    const selectedType = languages.find((type) => type.id === value);
                                    fetchType(selectedType.code)

                                }}
                            >
                                {languages?.map((type) => (
                                    <Option value={type.id} key={type.id}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>

                        <Form.Item
                            label="Название"
                            name="name"
                            rules={[
                                { required: !edit, message: 'Пожалуйста, введите название продукта!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Размеры"
                            name="hasSizes"
                        >
                            <Switch
                                checked={hasSizes}
                                onChange={setHasSizes}
                                checkedChildren="Да"
                                unCheckedChildren="Нет"
                            />
                        </Form.Item>

                        {hasSizes && (
                            <>

                                <Form.Item
                                    label="Цена средний"
                                    name="size_medium_price"
                                >
                                    <InputNumber min={0} />
                                </Form.Item>

                                <Form.Item
                                    label="Цена большой"
                                    name="size_large_price"
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label="Цена"
                            name="price"
                            rules={[
                                { required: !edit && !hasSizes, message: 'Пожалуйста, введите цену!' }
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>

                        <Form.Item
                            label="Кэшбек"
                            name="isCashback"
                        >
                            <Switch
                                checked={isCashBack}
                                onChange={setIsCashback}
                                checkedChildren="Да"
                                unCheckedChildren="Нет"
                            />
                        </Form.Item>

                        {isCashBack && (
                            <Form.Item
                                label="Сумма кэшбека"
                                name="cashback_price"
                                rules={[
                                    { required: isCashBack && !edit, message: 'Пожалуйста, введите сумму кэшбека!' }
                                ]}
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Chees Board"
                            name="hasCheesBoard"
                        >
                            <Switch
                                checked={hasCheesBoard}
                                onChange={setHasCheesBoard}
                                checkedChildren="Да"
                                unCheckedChildren="Нет"
                            />
                        </Form.Item>

                        {hasCheesBoard && (
                            <>
                                <Form.Item
                                    label="Цена маленький"
                                    name="chees_board_size_small_price"
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                    label="Цена средний"
                                    name="chees_board_size_medium_price"
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                    label="Цена большой"
                                    name="chees_board_size_large_price"
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                            </>


                        )}

                        <Form.Item
                            label="isHalf"
                            name="isHalf"
                        >
                            <Switch
                                checked={isHalf}
                                onChange={setIsHalf}
                                checkedChildren="Да"
                                unCheckedChildren="Нет"
                            />
                        </Form.Item>

                        <div className={'w-full '}>
                            <p className={'text font-bold text-blue-700 text-2xl mt-2'}> Полное описание </p>
                            <TextArea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Введите полное описание"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                            <p className={'text font-bold text-blue-700 text-2xl mt-2'}> Краткое описание </p>
                            <TextArea
                                value={brief}
                                onChange={(e) => setBreif(e.target.value)}
                                placeholder="Введите краткое описание"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </div>

                        <Form.Item
                            label="Категория"
                            name="categories_id"
                            rules={[
                                { required: !edit, message: 'Пожалуйста, выберите категорию!' }
                            ]}
                        >
                            <Select>
                                {types?.map((type) => (
                                    <Option value={type.id} key={type.id}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div className={'flex flex-row flex-wrap'}>
                            <Search
                                placeholder={'Поиск по категории и названию'}
                                style={{ width: '100%' }}
                                onSearch={handleSearch}
                            />
                        </div>
                        <div className={'flex flex-row flex-wrap mt-4 justify-center items-center mb-2 ml-2 w-full'}>
                            <p className={'font-bold text-[18px] text-blue-700'}> Обложка и Иконки </p>
                            <Switch onChange={() => setIsIcon(!isIcon)} checkedChildren={'Иконки'} unCheckedChildren={'Обложки'} className={'bg-green-600 ml-2'} />
                            {isIcon ? <p className={'font-bold text-[24px] text-blue-700 ml-8 text-center'}> Иконка</p> :
                                <p className={'text-[24px] ml-8 font-bold text-green-700'}> Обложка</p>}
                        </div>
                        <div className={'flex flex-row flex-wrap w-full mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>
                            {filteredImages.map(value => (
                                <div className={'flex flex-col ml-1'} key={value.id}>
                                    <img
                                        onClick={() => {
                                            setSelectedPath(value.id)
                                            message.success(value.path)
                                            iconsHandler(value.path)
                                        }}
                                        src={`https://api.italianopizza.uz/${value.path}`}
                                        className={`w-20 h-20 flex cursor-pointer m-1 ${
                                            !isIcon
                                                ? topIcons.includes(value.path)
                                                    ? 'text-white duration-200 border-green-700 border-2 rounded-lg'
                                                    : 'text-green-700'
                                                : covers.includes(value.path)
                                                    ? 'text-white duration-200 border-blue-700 border-2 rounded-lg'
                                                    : 'text-blue-700'
                                        }`}
                                        alt={`img-${value.id}`}
                                    />
                                    <p className={`text-[12px] font-bold ${
                                        !isIcon
                                            ? topIcons.includes(value.path)
                                                ? 'text-white duration-200 bg-green-800 rounded-lg'
                                                : ''
                                            : covers.includes(value.path)
                                                ? 'text-white duration-200 bg-blue-800 rounded-lg'
                                                : ''
                                    } text-blue-700 text-center`}>
                                        {value.name}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className={'w-full mt-4'}>
                            <p className={'text font-bold text-blue-700 text-2xl mt-2'}> Топики </p>
                            {topiconsList.map((topicon, index) => (
                                <div key={index} className={'flex flex-row items-center mb-2'}>
                                    <Form.Item
                                        label="Название"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <Input value={topicon.name} onChange={(e) => handleTopiconChange(index, 'name', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Цена"
                                    >
                                        <InputNumber value={topicon.price} min={0} onChange={(value) => handleTopiconChange(index, 'price', value)} />
                                    </Form.Item>
                                    <Button className={'bg-red-500 text-center text-white'} type="danger" onClick={() => removeTopicon(index)}>-</Button>
                                </div>
                            ))}
                            <Button className={'bg-green-400'} type="primary" onClick={addTopicon}>Добавить топик</Button>
                        </div>
                    </Form>
                </Modal>

                <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                    <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns} />
                </div>

                <Drawer title={`${selectedBottles?.name}`} onClose={onClose} open={open}>
                    <Typography className={'self-start ml-2 bg-blue-700 text-white text-center rounded mb-2'}>
                        Обложки
                    </Typography>
                    <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                        <Carousel style={{ width: '300px', borderRadius: "10px" }}>
                            {selectedBottles?.cover?.map(value =>
                                <div key={value}>
                                    <img src={`https://api.italianopizza.uz/${value}`} className={'w-full h-[300px]'} alt={`carousel-${value}`} />
                                </div>
                            )}
                        </Carousel>

                        <div className={'flex flex-row w-full'}>
                            <BiMoney className={'text-green-600 text-4xl'} />
                            <p className={'text-[13px] ml-2 bg-green-800 text-white rounded-lg p-1 mt-1'}>
                                Цена продукта: {selectedBottles?.price} руб.
                            </p>
                        </div>
                        <Typography className={'self-start ml-2 text-3xl text-gray-400'}>
                            Полное описание
                        </Typography>

                        <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                            <p className={'text-white text-[14px]'}>
                                {selectedBottles?.description}
                            </p>
                        </div>
                        <Typography className={'self-start ml-2 text-3xl text-gray-400'}>
                            Краткое описание
                        </Typography>

                        <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                            <p className={'text-white text-[14px]'}>
                                {selectedBottles?.brief_description}
                            </p>
                        </div>
                    </div>
                </Drawer>
            </div>
            <Modal
                visible={modalVisibleTable}
                onCancel={() => setModalVisibleTable(false)}
                footer={null}
            >
                <img src={selectedImageTable} alt="Selected Image" style={{ width: '100%' }} />
            </Modal>
            <Modal
                title="Полное описание"
                open={isModalOpen2}
                onOk={handleOk2}
                onCancel={handleCancel2}
                cancelText={'Назад'}
                okText={'Подтвердить'}
                okButtonProps={{ style: { backgroundColor: '#3742fa' } }}
                cancelButtonProps={{
                    style: {
                        backgroundColor: '#7bed9f',
                        color: 'white'
                    }
                }}
            >
                <p>{selectDescription}</p>
            </Modal>
        </div>
    );
}
