import React, {useEffect, useState} from "react";
import "./style.css";
import {Button, Drawer, Dropdown, Form, Image, Input, message, Modal, Popconfirm, Table, Upload} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import { deleteAdmin, showAdmin, updateAdmin} from "../../../api/service_admins";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {useSelector} from "react-redux";
import {addUserWithRole, deleteUser, getUser, showUser, updateUser} from "../../../api/users_api";
import {PiTelegramLogoThin} from "react-icons/pi";

export default function Couriers() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [admins, setAdmins] = useState([])
    const [adminsOld, setAdminsOld] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState([]);

    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [form] = Form.useForm();


    const theme = useSelector((state) => state.theme.theme);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };


    useEffect(() => {
        fetchAdmins()
    }, [])

    const fetchAdmins = async () => {
        setLoading(true);
        try {
            const data = await getUser('user-couriers');
            setAdmins(data);
            setAdminsOld(data)
        } catch (error) {
            console.error('Error fetching admins:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){

                await updateUser(selectedAdmin.id,values);
                setAdmins(admins)
                message.success("Client movoffaqqiyatli o`zgartirildi")
                await fetchAdmins()
            } else {

                await addUserWithRole(values,'user-courier-create');
                setAdmins(admins)
                message.success("Client movoffaqqiyatli qo`shildi")
                await fetchAdmins()
            }
        } catch (error) {
            console.log('Error adding admins ', error)
        }
    }

    const deleteButtonProps = {
        style: {
            backgroundColor: 'red', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'To`liq F.I.O',
            dataIndex: 'courier',
            key: 'courier',
            render:(courier=>(
                <div>{courier.fullname}</div>
            ))
        },
        {
            title: 'Tel ',
            dataIndex: 'courier',
            key: 'courier',
            render:(courier)=>(
                <p className={'bg-green-600 rounded text-white w-fit p-[3px] cursor-pointer'}> + {courier.phoneNumber}</p>
            )
        },
        {
            title: 'Telegram ID ',
            dataIndex: 'courier',
            key: 'courier',
            render:(courier)=>(
                <div className={'w-full flex flex-row bg-blue-400 rounded text-white w-fit p-[3px] cursor-pointer items-center justify-center'}>
                    <PiTelegramLogoThin className={'m-2'}/>
                    <p  >  {courier.telegram_id}</p>
                </div>
            )
        },
        {
            title: 'Manzil',
            dataIndex: 'courier',
            key: 'courier',
            render:(courier)=>(
                <div className={'w-full flex flex-row bg-orange-400 rounded text-white w-fit p-[3px] cursor-pointer items-center justify-center'}>
                    <HomeOutlined className={'m-2'}/>
                    <p  >  {courier.address}</p>
                </div>
            )
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showUser(record.courier.id);
                        setSelectedAdmin(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>

                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedAdmin(record.courier)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Clientni o`chirish"
                        description="Are you sure to delete this admin?"
                        onConfirm={async () => {

                            await deleteUser(record.courier.id)
                             await fetchAdmins()
                        }
                        }
                        okButtonProps={deleteButtonProps}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: 'Full name',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(admins, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[admins])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setAdmins(adminsOld)
        } else {
            setAdmins(filterAdmins(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterAdmins(value) {
        return admins.filter(admin => {

            return admin.fullname.toLowerCase().includes(value) || admin.phone.toLowerCase().includes(value);

        });
    }

    return <div className={'w-full'}>
        <div className={` w-[100%] p-0`}>
            <div className={`header_admin`}>

                <div className={`title`}>
                    Курьеры
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={`tools`}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Добавить курьера +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                    className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'}  mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Formani to`ldiring" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Kuryer"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="To`liq ismi"
                        name="fullname"
                        rules={[
                            {
                                required:!edit,
                                message: 'Please input your fullname!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        label="Telefon raqami"
                        name="phoneNumber"
                        rules={[{required: !edit, message: 'ILtimos telefon raqamni kiriting!'}]}
                    >
                        <Input placeholder="Telefon raqami"/>
                    </Form.Item>

                    <Form.Item
                        label="Chat id"
                        name="chat_id"
                        rules={[{required: !edit, message: 'ILtimos Chat id kiriting!'}]}
                    >
                        <Input placeholder="Telefon raqami"/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: !edit, message: 'ILtimos parolni kiriting!'}]}
                    >
                        <Input.Password placeholder="Password"/>
                    </Form.Item>

                    <Form.Item
                        label="Manzil"
                        name="address"
                        rules={[{required: !edit, message: 'ILtimos manzilni kiriting!'}]}
                    >
                        <Input placeholder="Mainzil"/>
                    </Form.Item>


                    <Form.Item
                        label="Telegram_id"
                        name="telegram_id"
                        rules={[{required: !edit, message: 'ILtimos telegramni id ni kiriting!'}]}
                    >
                        <Input placeholder="Telegram id"/>
                    </Form.Item>

                </Form>
            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`} `}>
                <Table className={'shadow-2xl w-full rounded-xl'} dataSource={admins} columns={columns}/>
            </div>

            <Drawer title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'w-9/12 h-fit'}>
                        <img src={`https://testarena.site/storage/${selectedAdmin?.photo}`}
                             className={'w-full h-full object-cover'}/>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Full name : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.fullname}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Phone number : </p>

                        <p className={'text-xl font-semibold'}>{selectedAdmin?.phone}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
