import React, {useState} from "react";
import "./style.css";

import {Button, Form, Input, message} from "antd";
import logo from '../../../assets/images/basket.png'
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../store/actions/authActions";
import axios from "axios";
import {loginIn} from '../../../api/auth';
const baseUrl = "https://65ae4b0d1dfbae409a74790d.mockapi.io/water/driver/";

export default function Login() {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    const onFinish = async (values) => {
        try {
            const response = await loginIn(values)
            localStorage.setItem('token',response.token);
            localStorage.setItem('user',response.user.fullname);
            dispatch(login(true))
            message.success('Добро пожаловать в панель управления ItalianoPizza ! ' + response.user.fullname)
        } catch (error) {
            message.error('Неверные данные')
        }

        // axios.post(baseUrl,{values}).then(response=> console.log(response))
        // axios.get(`${baseUrl}/token`).then(response=> localStorage.setItem('token', response))

    };



    return<>
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className={`loginForm`}>
                <img src={logo} alt="Логотип" className="mx-auto mb-6" style={{ maxWidth: '100px' }} />
                <h1 className="text-3xl font-bold mb-6 text-center">Admin panelga kirish</h1>
                <Form
                    name="loginForm"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="phoneNumber"
                        rules={[{ required: true, message: 'Iltimos telefon raqamingizni kiriting!' }]}
                        className={`loginInput`}
                    >
                        <Input placeholder="Telefon raqam" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                        className={`loginInput`}
                    >
                        <Input.Password placeholder="Пароль" />
                    </Form.Item>

                    <Form.Item>
                        <Button  htmlType="submit"  loading={loading} className={`loginButton bg-sky-600 text-white  font-bold`}>
                            Kirish
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </>
}
