import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table, Typography
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";
import {addIcons, getIcons, updateIcons} from "../../../api/Icons_api";
import {getImages} from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import {
    addCategory,
    deleteCategory,
    getCategory,
    getCategoryByLang,
    showCategory,
    updateCategory
} from "../../../api/Category_api";
import TextArea from "antd/es/input/TextArea";
import {getCategoryType} from "../../../api/categoryType_api";

export default function Category() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);
    const [languages , setLanguages] = useState([]);
    const [selectedlanguages , setSelectedLanguages] = useState([]);
    const [types , setTypes] = useState([]);
    const [brief, setBreif] = useState('');

    const [filteredImages, setFilteredImages] = useState([]);

    const [form] = Form.useForm();


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchBottles()
        fetchLanguage()
        fetchType('uz')
        fetchImagees()

    }, [])

    useEffect(()=>{},[types])

    const fetchImagees = async () => {
        try {
            const data = await getIcons();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const fetchBottles = async () => {
        let lang = 'uz'
        if (languages.length !==0){
            lang= languages[0].code;
        }
        setLoading(true);
        try {
            const data = await getCategoryByLang(lang);
            setBottles(data);
            setBottlesOld(data)
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        console.log(selectedPath)
        try {
            if (edit) {
          const   data =    await updateCategory(selectedBottles.id, values);
                values.icon_id = selectedPath
                values.brief_description = brief
                setBottles(bottles)
                console.log(data)
                message.success("Categoriya muvoffaqiyatli o`zgardi")
                await fetchBottles()
            } else {
                values.icon_id = selectedPath
                values.brief_description = brief
            const data =    await addCategory(values);
                console.log(data)
                setBottles(bottles)
                message.success("Categoriya muvoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Turi',
            dataIndex: 'type',
            key: 'type',
            render: type => type.name
        },
        {
            title: 'qisqa ma`lumot',
            dataIndex: 'brief_description',
            key: 'brief_description',
        },
        {
            title: 'icon',
            render: (record) => {
                return <img src={`https://api.italianopizza.uz/${record.icon}`} className={'w-20 h-20 object-cover'}/>
            },
            dataIndex: 'icon',
            key: 'icon',
        },

        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showCategory(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined/>
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Categoriyani o`chirish "
                        description="Haqiqatdan ham o`chirishni xohlaysizmi ?"
                        onConfirm={async () => {

                            await deleteCategory(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="ha"
                        cancelText="yo`q"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    const fetchLanguage = async () => {
        try {
            const data     =  await  getLanguage()
            console.log(data)
            setLanguages(data)
        } catch (e){

        }

    }
    const fetchType = async (e) => {
        message.info(e)
        try {
            // Находим объект с заданным id в массиве languages
            const language = languages.find(lang => lang.id === e);

            // Если объект найден, извлекаем из него code
            if (language) {
                const code = language.code;

                // Вызываем getCategoryType с полученным code
                const data = await getCategoryType(code);
                console.log(data);
                setTypes(data);
            } else {
                const data = await getCategoryType(e);
                console.log(data);
                setTypes(data);
            }
        } catch (e){

        }
    }
    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {

            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.Price.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };
    const handleLanguage= async (e)=>{
        setLoading(true);
        try {
            const data = await getCategoryByLang(e);
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);

    }


    const search = (value) => {
        message.info(value)
    }
    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Категории
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                           Добавить категорию +
                        </Button>

                        <Button type={"primary"} onClick={() => {
                            handleClick()
                        }}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel/>
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <Select placeholder={'Tilni tanlang'} className={'w-[200px] mr-2'} onChange={handleLanguage}>
                            {languages?.map((type) => (
                                <Option  value={type.code}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                        <div
                            className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search
                        </div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Iconka" open={isModalOpen || edit} okButtonProps={confirmButtonProps}
                   onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Iconka qo`shish"
                    initialValues={{remember: true, cashbackPercent: 0}}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Tilni tanlang"
                        name="language_id"
                        rules={[
                            {
                                required: true,
                                message: 'Tilni tanlang!',
                            },
                        ]}
                    >
                        <Select onChange={fetchType}>
                            {languages?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle name!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <div className={'w-full '}>
                        <TextArea
                            value={brief}
                            onChange={(e) => setBreif(e.target.value)}
                            placeholder="Controlled autosize"
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />

                    </div>
                    <Form.Item
                        label="Categoriya turi"
                        name="type_id"
                        rules={[
                            {
                                required: true,
                                message: 'Categoriya turini tanlang!',
                            },
                        ]}
                    >
                        <Select>
                            {types?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <div className={'flex flex-row flex-wrap'}>

                        <Search
                            placeholder={'Kategoriya va nomi bo`yicha izlash '}
                            style={{width:'100%'}}
                            onSearch={handleSearch}
                        />
                    </div>

                    <div className={'flex flex-row flex-wrap mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>

                        {
                            filteredImages.map(value =><div className={'flex flex-col'}>
                                <img onClick={() => {
                                    setSelectedPath(value.id)
                                    message.success(value.icon)
                                }
                                } src={`https://api.italianopizza.uz/${value.icon}`}
                                     className={`w-20 h-20 flex cursor-pointer m-1  ${selectedPath === value.id ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800':''}`}/>

                                <p className={`text-[12px] font-bold  ${selectedPath === value.id ? 'text-white duration-200 bg-blue-800 rounded-lg' :''} text-blue-700  text-center`}>{value.name}</p>
                            </div>)
                        }
                    </div>


                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer title="Bottle" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>
                    <div className={'flex flex-col w-full items-center border-2 border-blue-700 rounded-lg p-2'}>
                        <img src={`https://api.italianopizza.uz/${selectedBottles?.icon?.icon}`} className={'w-[200px] h-[200px]'}/>
                        <p className={'text-[13px]  ml-2 bg-blue-800 text-white rounded-lg p-1 mt-1'}>iconka nomi :  ${selectedBottles?.icon?.name} </p>
                        <p className={'text-[13px]  ml-2 bg-blue-800 text-white rounded-lg p-1 mt-1'}>iconka index :  ${selectedBottles?.icon?.index} </p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>Turi </Typography>
                    <div className={'flex flex-row'}>
                        <p className={'text-[13px]  ml-2 bg-orange-800 text-white rounded-lg p-1 mt-1'}>Turining nomi :  ${selectedBottles?.type?.name} </p>
                        <p className={'text-[13px]  ml-2 bg-orange-800 text-white rounded-lg p-1 mt-1'}>Turining nomi :  ${selectedBottles?.type?.name} </p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>Qisqacha ma`lumot </Typography>

                    <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                        <p className={'text-white text-[14px]'}>
                        ${selectedBottles?.brief_description}
                        </p>
                    </div>


                </div>


            </Drawer>
        </div>
    </div>
}
